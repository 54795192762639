import React, {useEffect, useState} from 'react';
import {Container} from 'reactstrap';
import LoadingView from "../../../common/LoadingView";
import JobService from "../../service/JobService";
import JobCard from "../../../common/JobCard";

const JobRecommendationsView = (userType) => {

    const [recommendedJobs, setRecommendedJobs] = useState([]);
    const [loading, setLoading] = useState(false);
    const jobService = JobService();

    useEffect(() => {
        setLoading(true);
        jobService.getEmployeeJobRecommendations().then(response => {
            setRecommendedJobs(response.data);
            setLoading(false);
        });
    }, [setRecommendedJobs]);

    if (loading) {
        return <LoadingView/>
    }

    return (
        <Container fluid>
            <h5>Jobs you may like</h5>
            {
                recommendedJobs.map(job => {
                    return <JobCard key={job.id} job={job} userType={userType}/>
                })
            }
        </Container>
    );
};

export default JobRecommendationsView;
