import useApiRequest from '../../common/useApiRequest';

const JobService = () => {
    const apiRequest = useApiRequest();

    const createJob = (job) => apiRequest('post', '/api/jobs', job);
    const getJob = (id) => apiRequest('get', `/api/jobs/${id}`);
    const updateJob = (job) => apiRequest('put', '/api/jobs', job);
    const deleteJob = (id) => apiRequest('delete', `/api/jobs/${id}`);
    const listJobs = () => apiRequest('get', '/api/jobs');

    const applyJob = (id) => apiRequest('get', `/api/jobs/${id}/apply`);
    const getJobApplications = (id) => apiRequest('get', `/api/jobs/${id}/applications`);
    const getEmployeeJobRecommendations = () => apiRequest('get', '/api/jobs/recommendations');
    const getEmployeeAppliedJobs = () => apiRequest('get', '/api/jobs/applied');
    const searchJobs = (jobSearch) => apiRequest('post', '/api/jobs/search', jobSearch);
    const updateJobApplication = (jobApplication) => apiRequest('put', `/api/jobs/applications`, jobApplication);
    return {
        createJob,
        getJob,
        updateJob,
        deleteJob,
        listJobs,
        applyJob,
        getJobApplications,
        updateJobApplication,
        getEmployeeJobRecommendations,
        getEmployeeAppliedJobs,
        searchJobs,
    };
};

export default JobService;