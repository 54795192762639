import React, {useState} from 'react';
import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import classnames from 'classnames';
import JobRecommendationsView from "./JobRecommendationsView";
import JobSearchView from "./JobSearchView";
import EmployeeJobListView from "./EmployeeJobListView";

const EmployeeDashboard = ({userType}) => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <div>
            <JobSearchView userType={userType}/>
            <Nav tabs>
                <NavItem>
                    <NavLink href="#"
                             className={classnames({active: activeTab === '1'})}
                             onClick={() => {
                                 toggle('1');
                             }}
                    >
                        Job Recommendations
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="#"
                             className={classnames({active: activeTab === '2'})}
                             onClick={() => {
                                 toggle('2');
                             }}
                    >
                        Applied Jobs
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <JobRecommendationsView userType={userType}/>
                </TabPane>
                <TabPane tabId="2">
                    <EmployeeJobListView userType={userType}/>
                </TabPane>
            </TabContent>
        </div>
    );
};

export default EmployeeDashboard;