import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup, Container, Table} from 'reactstrap';
import {Link} from 'react-router-dom';
import LoadingView from "../../../common/LoadingView";
import JobService from "../../service/JobService";
import JobCard from "../../../common/JobCard";

const JobListView = (userType) => {

    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(false);
    const jobService = JobService();

    useEffect(() => {
        setLoading(true);

        jobService.listJobs()
            .then(response => {
                const data = response.data;
                setJobs(data);
                setLoading(false);
            })
    }, []);

    if (loading) {
        return <LoadingView/>
    }

    const jobsList = jobs.map(job => {
        return <JobCard key={job.id} job={job} userType={userType} showApplyButton={true}/>
    });

    return (
        <div>
            <Container fluid>
                <div className="float-end">
                    <Button outline color="success" tag={Link} to="/jobs/new">Post a Job</Button>
                </div>
                <h3>Posted Jobs</h3>
                {jobsList}
            </Container>
        </div>
    );
};

export default JobListView;
