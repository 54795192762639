import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import UserProfileVIew from "./user/UserProfileVIew";
import UserProfileDetailsView from "./user/UserProfileDetailsView";
import Dashboard from "./Dashboard";
import NewJobView from "./job/view/employeer/NewJobView";
import JobEditView from "./job/view/employeer/JobEditView";
import JobDisplayView from "./job/view/employee/JobDisplayView";
import JobApplicationListView from "./job/view/employeer/JobApplicationListView";
import JobSearchResultsView from "./job/view/employee/JobSearchResultsView";
import JobApplicantProfileView from "./user/JobApplicantProfileView";
import LandingPage from "./LandingPage";
import Home from "./Home";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<LandingPage/>}/>
                <Route exact path="/home" element={<Home/>}/>

                <Route path="/profile" exact={true} element={<UserProfileVIew/>}/>
                <Route path="/profile-details" exact={true} element={<UserProfileDetailsView/>}/>

                <Route path="/dashboard" exact={true} element={<Dashboard/>}/>

                <Route path="/jobs/new" exact={true} element={<NewJobView/>}/>
                <Route path="/jobs/:id" exact={true} element={<JobEditView/>}/>
                <Route path="/jobs/view/:id" exact={true} element={<JobDisplayView/>}/>
                <Route path="/jobs/:id/applications" exact={true} element={<JobApplicationListView/>}/>
                <Route path="/jobs/search" exact={true} element={<JobSearchResultsView/>}/>
                <Route path="/applicant-profile/:id" exact={true} element={<JobApplicantProfileView/>}/>
            </Routes>
        </Router>
    )
}

export default App;
