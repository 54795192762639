class Job {
    constructor(title = '', company = '', location = '',
                skills = '', experienceLevel = '', jobType = '',
                jobRole = '', salary = '', jobDescription = '') {

        this.title = title;
        this.company = company;
        this.location = location;
        this.skills = skills;
        this.experienceLevel = experienceLevel;
        this.jobType = jobType;
        this.jobRole = jobRole;
        this.salary = salary;
        this.jobDescription = jobDescription;
    }
}

export default Job;