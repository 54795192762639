import React from "react";

const CoachDashboard = (userType) => {

    return (
        <div>
            <h2>Coming soon...</h2>
        </div>
    );
}

export default CoachDashboard;
