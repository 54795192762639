import React, {useEffect, useState} from 'react';
import './App.css';
import AppNavbar from './AppNavbar';
import {useNavigate} from 'react-router-dom';
import {Container} from 'reactstrap';
import LoadingView from "./common/LoadingView";
import UserService from "./job/service/UserService";
import EmployeeDashboard from "./job/view/employee/EmployeeDashboard";
import EmployerDashboard from "./job/view/employeer/EmployerDashboard";
import CoachDashboard from "./job/view/coach/CoachDashboard";

const Dashboard = () => {

    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(undefined);
    const navigate = useNavigate();
    const userService = UserService();

    useEffect(() => {
        setLoading(true);
        userService.getUser().then(response => {
            const data = response.data
            if (data !== '') {
                setUser(data);
                userService.getProfile()
                    .then(response => {
                        const data = response.data;
                        setUser(data);
                        if (data.userType === null || data.userType === '') {
                            navigate('/profile');
                        }
                    });
            }
            setLoading(false);

        });
    }, [setLoading, setUser])

    if (loading) {
        return <LoadingView/>
    }

    return (
        <div>
            <AppNavbar/>
            <Container fluid>
                {user ?
                    <div>
                        {user.userType === 'Recruiter/Hiring Manager' && (
                            <EmployerDashboard userType={user.userType}/>
                        )}
                        {user.userType === 'Job Seeker' && (
                            <EmployeeDashboard userType={user.userType}/>
                        )}
                        {user.userType === 'Career Coach' && (
                            <CoachDashboard userType={user.userType}/>
                        )}
                    </div> :
                    <div>
                        <h2>Please log in to search for jobs.</h2>
                    </div>}
            </Container>
        </div>
    );
}

export default Dashboard;
