import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
    Button,
    Container,
    Form,
    FormGroup,
    Input,
    Alert,
} from 'reactstrap';
import './UserProfileView.css';
import AppNavbar from "../AppNavbar";
import UserService from "../job/service/UserService";

const UserProfileVIew = () => {
    const initialFormState = {
        userType: 'Job Seeker',
    };
    const [profile, setProfile] = useState(initialFormState);
    const navigate = useNavigate();
    const {id} = useParams();
    const [alertData, setAlertData] = useState({color: 'danger', message: '', visible: false});
    const userService = UserService();

    useEffect(() => {
        userService.getProfile().then(response => {
            const data = response.data;
            if (data.userType !== null && data.userType !== '') {
                navigate('/profile-details');
            } else {
                data.userType = 'Job Seeker';
            }
            setProfile(data)
        });
    }, [id, setProfile, navigate]);

    const onDismiss = () => setAlertData({...alertData, visible: false});

    const handleChange = (event) => {
        const {name, value} = event.target;
        setProfile({...profile, [name]: value});
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const response = await userService.updateProfile(profile);

        if (response.status === 200) {
            navigate('/profile-details');
        } else {
            setAlertData({color: 'danger', message: "Failed with error: " + response.data, visible: true});
        }
    };

    return (
        <div>
            <AppNavbar/>
            <Container>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <div className="row">

                            <FormGroup className="col-md-2">
                                <h4>Who are you?</h4>
                            </FormGroup>

                            <FormGroup className="col-md-2">
                                <Input
                                    type="select"
                                    name="userType"
                                    id="userType"
                                    onChange={handleChange}
                                    value={profile.userType}>
                                    <option value="Job Seeker">Job Seeker</option>
                                    <option value="Recruiter/Hiring Manager">Recruiter/Hiring Manager</option>
                                    <option value="Career Coach">Career Coach</option>
                                </Input>
                            </FormGroup>
                            <FormGroup className="col-md-8">
                                <Button color="primary" type="submit">Go</Button>{' '}
                            </FormGroup>
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <Alert color={alertData.color} isOpen={alertData.visible} toggle={onDismiss}>
                            {alertData.message}
                        </Alert>
                    </FormGroup>

                </Form>
            </Container>
        </div>
    );
};

export default UserProfileVIew;