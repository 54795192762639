import React, {useEffect, useState} from 'react';
import './App.css';
import AppNavbar from './AppNavbar';
import {useNavigate} from 'react-router-dom';
import {Container} from 'reactstrap';
import LoadingView from "./common/LoadingView";
import UserService from "./job/service/UserService";

const Home = () => {

    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(undefined);
    const navigate = useNavigate();
    const userService = UserService();

    const login = () => {
        let port = (window.location.port ? ':' + window.location.port : '');
        if (port === ':3000') {
            port = ':8080';
        }
        window.location.href = `//${window.location.hostname}${port}/api/private?redirect_uri=${window.location.origin}/home`;
        // window.location.href = `//${window.location.hostname}${port}/oauth2/authorization/linkedin`;
    }

    useEffect(() => {
            setLoading(true);
            userService.getUser().then(response => {
                const data = response.data
                if (data !== '') {
                    setUser(data);
                    userService.getProfile()
                        .then(response => {
                            const data = response.data;
                            if (data.userType === null || data.userType === '') {
                                navigate('/profile');
                            } else {
                                navigate('/dashboard');
                            }
                        }).finally(() => {
                        setLoading(false);
                    });

                } else {
                    login();
                }
            });
        }, [setLoading, setUser]
    )

    const homeBody = user ?
        <div>
        </div> :
        <div>
            <h2>Please log in to search for jobs.</h2>
        </div>;

    if (loading) {
        return <LoadingView/>
    }

    return (
        <div>
            <AppNavbar/>
            <Container fluid>
                {homeBody}
            </Container>
        </div>
    );
}

export default Home;
