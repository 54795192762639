import React from 'react';
import '../App.css';
import {Container, Spinner} from 'reactstrap';

const LoadingView = () => {
    return (
        <div>
            <Container fluid>
                <div className="d-flex justify-content-center align-items-center vh-100">
                    <div className="text-center">
                        <Spinner style={{width: '2rem', height: '2rem'}}/>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default LoadingView;
