import {
    Briefcase,
    Users,
    BookOpen,
    ArrowRight,
    Linkedin,
    Twitter,
    Facebook,
    Instagram,
} from "lucide-react";

import {useNavigate} from 'react-router-dom';
import React, {useState, useRef} from "react";
import BrandLogo from "./common/BrandLogo";

const LandingPage = () => {

    const navRef = useRef(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const navigator = useNavigate();

    const homeRef = useRef(null);
    const featuresRef = useRef(null);
    const aboutRef = useRef(null);
    const contactRef = useRef(null);

    const handleLogin = async (e) => {
        navigator("/home");
    };

    const handleGetStarted = (userType) => {
        switch (userType) {
            case "job-seekers":
                navigator("/jobseeker/dashboard");
                break;
            case "recruiters":
                navigator("/recruiter/dashboard");
                break;
            case "coaches":
                navigator("/coach/dashboard");
                break;
            default:
                break
        }
    };

    const handleNavigation = (section) => {
        switch (section) {
            case 'home':
                homeRef.current.scrollIntoView({behavior: 'smooth'});
                break;
            case 'features':
                featuresRef.current.scrollIntoView({behavior: 'smooth'});
                break;
            case 'about':
                aboutRef.current.scrollIntoView({behavior: 'smooth'});
                break;
            case 'contact':
                contactRef.current.scrollIntoView({behavior: 'smooth'});
                break;
            default:
                break;
        }
    };

    const userTypes = [
        {
            id: "job-seekers",
            icon: <Briefcase className="h-8 w-8 text-[#0077B5]"/>,
            title: "Job Seekers",
            features: [
                "Profile optimization",
                "Job matching",
                "Interview prep",
                "Accelerate connections",
            ],
        },
        {
            id: "recruiters",
            icon: <Users className="h-8 w-8 text-[#0077B5]"/>,
            title: "Recruiters",
            features: [
                "Talent pool access",
                "Advanced search",
                "Direct messaging",
                "Analytics dashboard",
            ],
        },
        {
            id: "coaches",
            icon: <BookOpen className="h-8 w-8 text-[#0077B5]"/>,
            title: "Career Coaches",
            features: [
                "Client matching",
                "Session scheduling",
                "Resource library",
                "Progress tracking",
            ],
        },
    ];
    return (
        <div className="min-h-screen bg-[#F3F2EF]">
            <nav ref={navRef}
                 className="sticky top-0 z-50 bg-white shadow-sm"
                 role="navigation"
                 aria-label="Main navigation">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between items-center h-16">

                        <BrandLogo/>

                        <div className="hidden md:block">
                            <ul className="ml-10 flex items-center space-x-8" role="menubar">
                                {["Home", "Features", "About", "Contact"].map((item) => (
                                    <li key={item} role="none">
                                        <button
                                            onClick={() => handleNavigation(item.toLowerCase())}
                                            className="text-gray-600 hover:text-[#0077B5] transition-colors"
                                            role="menuitem">
                                            {item}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="flex items-center">
                            <button
                                onClick={handleLogin}
                                className="bg-[#0077B5] text-white px-6 py-2 rounded-md flex items-center space-x-2 hover:bg-[#00A0DC] transition-colors"
                                aria-label="Login with LinkedIn">
                                <span>Login with LinkedIn</span>
                            </button>
                        </div>
                    </div>

                    {/* Mobile menu */}
                    {mobileMenuOpen && (
                        <div className="md:hidden">
                            <div className="px-2 pt-2 pb-3 space-y-1">
                                {["Home", "Features", "About", "Contact"].map((item) => (
                                    <button
                                        key={item}
                                        onClick={() => {
                                            handleNavigation(item.toLowerCase());
                                            setMobileMenuOpen(false);
                                        }}
                                        className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-[#0077B5] hover:bg-gray-50"
                                    >
                                        {item}
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </nav>

            <main>
                <section ref={homeRef}
                         className="relative bg-white overflow-hidden"
                         aria-labelledby="hero-title"
                >
                    <div className="max-w-7xl mx-auto">
                        <div
                            className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                            <div
                                className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                                <div className="sm:text-center lg:text-left">
                                    <h1
                                        id="hero-title"
                                        className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl"
                                    >
                                        <span className="block">Reconnect IT Talent</span>
                                        <span className="block text-[#0077B5]">
                      with Opportunities
                    </span>
                                    </h1>
                                    <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                        Supporting laid-off IT professionals in their journey back
                                        to meaningful careers. Join our community of tech talent,
                                        recruiters, and career coaches.
                                    </p>
                                    <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                                        <button
                                            onClick={handleLogin}
                                            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#0077B5] hover:bg-[#00A0DC] md:py-4 md:text-lg md:px-10 transition-colors"
                                            aria-label="Connect with LinkedIn"
                                        >
                                            Connect with LinkedIn
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section ref={featuresRef}
                         className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12"
                         aria-labelledby="user-types-title">
                    <h2 id="user-types-title" className="sr-only">
                        User Types
                    </h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        {userTypes.map((card) => (
                            <div key={card.id}
                                 className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
                                 role="article"
                                 aria-labelledby={`${card.id}-title`}>
                                <div className="flex items-center mb-4">
                                    {React.cloneElement(card.icon, {
                                        "aria-hidden": "true",
                                    })}
                                    <h3 id={`${card.id}-title`}
                                        className="ml-3 text-xl font-semibold text-gray-900">
                                        {card.title}
                                    </h3>
                                </div>
                                <ul className="space-y-3 mb-6" role="list">
                                    {card.features.map((feature) => (
                                        <li key={feature}
                                            className="flex items-center text-gray-600">
                                            <ArrowRight
                                                className="h-4 w-4 text-[#0077B5] mr-2"
                                                aria-hidden="true"/>
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                                <button
                                    onClick={() => handleGetStarted(card.id)}
                                    className="w-full px-4 py-2 bg-[#F3F2EF] text-[#0077B5] rounded-md
                                     hover:bg-[#0077B5] hover:text-white transition-colors"
                                    aria-label={`Get started as ${card.title}`}>
                                    Get Started
                                </button>
                            </div>
                        ))}
                    </div>
                </section>

                <section ref={aboutRef} className="bg-white py-12" aria-labelledby="statistics-title">
                    <h2 id="statistics-title" className="sr-only">
                        Statistics
                    </h2>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="grid md:grid-cols-4 gap-8 text-center">
                            {[
                                {
                                    id: "professionals",
                                    number: "100+",
                                    label: "IT Professionals",
                                },
                                {
                                    id: "companies",
                                    number: "10+",
                                    label: "Partner Companies",
                                },
                                {
                                    id: "placements",
                                    number: "100+",
                                    label: "Successful Placements",
                                },
                                {
                                    id: "satisfaction",
                                    number: "95%",
                                    label: "Satisfaction Rate",
                                },
                            ].map((stat) => (
                                <div key={stat.id} className="p-6">
                                    <p className="text-4xl font-bold text-[#0077B5]">
                                        {stat.number}
                                    </p>
                                    <p className="mt-2 text-gray-600">{stat.label}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <section
                    className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12"
                    aria-labelledby="features-title">
                    <h2 id="features-title" className="sr-only">
                        Feature Highlights
                    </h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        {[
                            {
                                id: "matching",
                                title: "Smart Matching",
                                description: "AI-powered job matching system",
                            },
                            {
                                id: "connections",
                                title: "Instant Connections",
                                description: "Direct messaging with recruiters",
                            },
                            {
                                id: "growth",
                                title: "Career Growth",
                                description: "Personalized development plans",
                            },
                            {
                                id: "library",
                                title: "Resource Library",
                                description: "Comprehensive learning materials",
                            },
                            {
                                id: "community",
                                title: "Community Support",
                                description: "Network with peers",
                            },
                            {
                                id: "guidance",
                                title: "Expert Guidance",
                                description: "Access to career coaches",
                            },
                        ].map((feature) => (
                            <div
                                key={feature.id}
                                className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow transform hover:-translate-y-1"
                                role="article">
                                <h3 className="text-lg font-semibold text-[#283E4A] mb-2">
                                    {feature.title}
                                </h3>
                                <p className="text-gray-600">{feature.description}</p>
                            </div>
                        ))}
                    </div>
                </section>
            </main>

            <footer ref={contactRef} className="bg-[#283E4A] text-white" role="contentinfo">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                    <div className="grid md:grid-cols-4 gap-8">
                        <div>
                            <span className="text-2xl font-bold">RiffedTalent</span>
                            <p className="mt-2 text-sm">
                                Accelerating Connections, Empowering Talent.
                            </p>
                        </div>

                        <nav aria-label="Quick links">
                            <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
                            <ul className="space-y-2" role="list">
                                {["About Us", "Features", "Pricing", "Contact"].map((item) => (
                                    <li key={item}>
                                        <button
                                            onClick={() => handleNavigation(item.toLowerCase())}
                                            className="hover:text-[#00A0DC] transition-colors">
                                            {item}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </nav>

                        <nav aria-label="Legal">
                            <h4 className="text-lg font-semibold mb-4">Legal</h4>
                            <ul className="space-y-2" role="list">
                                {["Privacy Policy", "Terms of Service", "Cookie Policy"].map(
                                    (item) => (
                                        <li key={item}>
                                            <button
                                                onClick={() => handleNavigation(item.toLowerCase())}
                                                className="hover:text-[#00A0DC] transition-colors">
                                                {item}
                                            </button>
                                        </li>
                                    ),
                                )}
                            </ul>
                        </nav>

                        <div>
                            <h4 className="text-lg font-semibold mb-4">Connect</h4>
                            <div className="flex space-x-4">
                                {[
                                    {
                                        Icon: Linkedin,
                                        label: "LinkedIn",
                                        href: "#",
                                    },
                                    {
                                        Icon: Twitter,
                                        label: "Twitter",
                                        href: "#",
                                    },
                                    {
                                        Icon: Facebook,
                                        label: "Facebook",
                                        href: "#",
                                    },
                                    {
                                        Icon: Instagram,
                                        label: "Instagram",
                                        href: "#",
                                    },
                                ].map(({Icon, label, href}) => (
                                    <a key={label}
                                       href={href}
                                       className="hover:text-[#00A0DC] transition-colors"
                                       aria-label={label}>
                                        <Icon aria-hidden="true"/>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="mt-8 pt-8 border-t border-gray-700 text-center">
                        <p className="text-sm">
                            © 2024 RiffedTalent. All rights reserved.
                        </p>
                    </div>
                </div>
            </footer>
        </div>
    );
}
export default LandingPage;