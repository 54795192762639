import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    Button,
    Container,
    Form,
    FormGroup,
    Input,
    Alert, Label,
} from 'reactstrap';
import {
    MDXEditor,
    headingsPlugin,
    listsPlugin,
    thematicBreakPlugin,
    quotePlugin,
    toolbarPlugin,
    UndoRedo,
    BoldItalicUnderlineToggles,
    BlockTypeSelect,
    CreateLink,
    InsertImage, ListsToggle, linkDialogPlugin, imagePlugin
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import './NewJobView.css';
import AppNavbar from "../../../AppNavbar";
import JobService from "../../service/JobService";
import Job from "../../model/Job";

const NewJobView = () => {

    const initialFormState = new Job('', '', '', '', '', 'Full-time', '', '', '');
    const [job, setJob] = useState(initialFormState);
    const [isCompanyValid, setCompanyValid] = useState(true);
    const [isTitleValid, setTitleValid] = useState(true);
    const [isLocationValid, setLocationValid] = useState(true);
    const navigate = useNavigate();
    const [alertData, setAlertData] = useState({color: 'danger', message: '', visible: false});
    const jobService = JobService();

    const onDismiss = () => setAlertData({...alertData, visible: false});

    const handleChange = (event) => {
        const {name, value} = event.target;
        if (name === 'company') {
            setCompanyValid(value.length > 0);
        }
        if (name === 'title') {
            setTitleValid(value.length > 0);
        }
        if (name === 'location') {
            setLocationValid(value.length > 0);
        }
        setJob({...job, [name]: value});
    };

    const validateForm = () => {
        return job.company && job.title && job.location;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) {
            setAlertData({color: 'danger', message: "Please fill in the required fields", visible: true});
            return;
        }
        const response = await jobService.createJob(job);
        if (response.status === 200) {
            navigate('/dashboard');
        } else {
            setAlertData({color: 'danger', message: "Failed with error: " + response.data, visible: true});
        }
    };

    return (
        <div>
            <AppNavbar/>
            <Container>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <div className="row">
                            <div className="col-md-4">
                                <FormGroup className="col-md-6">
                                    <h4>New Job Posting</h4>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="company">Company</Label>
                                    <Input type="text" name="company" id="company"
                                           value={job.company || ''}
                                           onChange={handleChange}
                                           invalid={!isCompanyValid}
                                           placeholder="Ex. Microsoft"/>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="title">Job Title</Label>
                                    <Input type="text" name="title" id="title"
                                           value={job.title || ''}
                                           onChange={handleChange}
                                           invalid={!isTitleValid}
                                           placeholder="Ex. Lead Python Engineer- Fintech Startup (Remote)"/>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="location">Job Location</Label>
                                    <Input type="text" name="location" id="location"
                                           value={job.location || ''}
                                           onChange={handleChange}
                                           invalid={!isLocationValid}
                                           placeholder="New York(Remote)"/>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="skills">Skills</Label>
                                    <Input type="text" name="skills" id="skills"
                                           value={job.skills || ''}
                                           onChange={handleChange}
                                           placeholder="Python, Project Management, Customer Acquisation ..."/>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="experienceLevel">Experience Level</Label>
                                    <Input type="select" name="experienceLevel" id="experienceLevel"
                                           value={job.experienceLevel || ''}
                                           onChange={handleChange}>
                                        <option value="">Select Experience Level</option>
                                        <option value="Internship">Internship</option>
                                        <option value="Entry level">Entry level</option>
                                        <option value="Associate">Associate</option>
                                        <option value="Mid-Senior level">Mid-Senior level</option>
                                        <option value="Director">Director</option>
                                        <option value="Executive">Executive</option>
                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="jobType">Type</Label>
                                    <Input type="select" name="jobType" id="jobType"
                                           value={job.jobType || ''} onChange={handleChange}>
                                        <option value="Full-time">Full-time</option>
                                        <option value="Part-time">Part-time</option>
                                        <option value="Contract">Contract</option>
                                        <option value="Internship">Internship</option>
                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="jobRole">Role</Label>
                                    <Input type="text" name="jobRole" id="jobRole"
                                           value={job.jobRole || ''}
                                           onChange={handleChange}
                                           placeholder="Ex. Senior Backend Engineer, Project Manager etc.."/>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="salary">Salary</Label>
                                    <Input type="text" name="salary" id="salary"
                                           value={job.salary || ''}
                                           onChange={handleChange}
                                           placeholder="Ex. $150,000"/>
                                </FormGroup>

                            </div>
                            <div className="col-md-6">
                                <Label for="jobDescription">Job Description</Label>
                                <FormGroup style={{position: 'relative'}}>
                                    <div className="editor-content">
                                        <MDXEditor
                                            name="jobDescription"
                                            id="jobDescription"
                                            placeholder="Provide Job Description"
                                            markdown={job.jobDescription}
                                            onChange={(value) => setJob({...job, jobDescription: value})}
                                            plugins={[
                                                headingsPlugin(), listsPlugin(),
                                                quotePlugin(), thematicBreakPlugin(),
                                                linkDialogPlugin(), imagePlugin(),
                                                toolbarPlugin({
                                                    toolbarContents: () => (<>
                                                        <UndoRedo/>
                                                        <BoldItalicUnderlineToggles/>
                                                        <BlockTypeSelect/>
                                                        <CreateLink/>
                                                        <InsertImage/>
                                                        <ListsToggle/>
                                                    </>)
                                                })
                                            ]}
                                        />
                                    </div>
                                </FormGroup>
                            </div>

                        </div>
                        <div className="row">
                            <FormGroup className="offset-4 col-md-8">
                                <Button color="primary" type="submit">Post Job</Button>{' '}
                            </FormGroup>
                        </div>
                    </FormGroup>

                    <FormGroup className="offset-2 col-md-4">
                        <Alert color={alertData.color} isOpen={alertData.visible} toggle={onDismiss}>
                            {alertData.message}
                        </Alert>
                    </FormGroup>

                </Form>
            </Container>
        </div>
    );
};

export default NewJobView;