import useApiRequest from '../../common/useApiRequest';

const UserService = () => {
    const apiRequest = useApiRequest();

    const logout = () => apiRequest('post', '/api/logout');
    const getUser = () => apiRequest('get', '/api/user');
    const deleteProfile = () => apiRequest('delete', '/api/profile');
    const getProfile = () => apiRequest('get', '/api/profile');
    const getApplicantProfile = (id) => apiRequest('get', `/api/applicant-profile/${id}`);
    const updateProfile = (profile) => apiRequest('put', '/api/profile', profile);

    return {
        logout,
        getUser,
        getProfile,
        getApplicantProfile,
        deleteProfile,
        updateProfile
    };
};

export default UserService;