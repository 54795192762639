import React from 'react';
import {Card, CardBody, CardTitle, CardSubtitle, CardText, Button, Label, Badge} from 'reactstrap';
import {Link} from 'react-router-dom';
import '../App.css';
import './JobCard.css';
import ReactMarkdown from "react-markdown";

const JobCard = ({job, userType}) => {
    const formattedDate = new Date(job.createdDate).toLocaleDateString('en-CA');

    return (
        <Card className="mb-3">
            <CardBody>
                <CardTitle tag="h5">
                    <Link className="link-underline-light" to={`/jobs/view/${job.id}`}> {job.title}</Link>
                </CardTitle>
                <CardSubtitle className="mb-2 text-muted">
                    <div className="row">
                        <div className="col-md-2">
                            <Label>Posted On: {formattedDate}</Label>
                        </div>
                        <div className="offset-6 col-md-2">
                            <Label><i className="fa-solid fa-location-dot"></i> {job.location}</Label>
                        </div>
                    </div>
                </CardSubtitle>
                <CardBody>
                    <ReactMarkdown>
                        {job.jobDescription.length > 500 ? job.jobDescription.substring(0, 500) + '...' : job.jobDescription || ''}
                    </ReactMarkdown>
                    <Button color="link" className="link-underline-light" tag={Link} to={`/jobs/view/${job.id}`}>View
                        Details</Button>
                </CardBody>
                <CardText>
                    {job.skills.split(',').map((skill, index) => (
                        <Badge color="light" pill key={index} className="m-1 text-bg-light badge-font">
                            {skill.trim()}
                        </Badge>
                    ))}
                </CardText>
                <div className="row">
                    <div className="col-md-2">
                        {userType.userType === 'Recruiter/Hiring Manager' &&
                            <Button outline color="primary" tag={Link}
                                    to={"/jobs/" + job.id + "/applications"}>View Applications</Button>
                        }
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default JobCard;