import axios from 'axios';
import ApiResponse from './ApiResponse';
import {useCookies} from 'react-cookie';

const useApiRequest = () => {
    const [cookies] = useCookies(['XSRF-TOKEN']);

    return async (method, url, data = {}) => {
        const config = {
            method,
            url,
            headers: {
                'X-XSRF-TOKEN': cookies['XSRF-TOKEN'],
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            data,
        };

        try {
            const response = await axios(config);
            return ApiResponse(response.status, response.data);
        } catch (error) {
            return ApiResponse(error.code, error.message);
        }
    };
};

export default useApiRequest;