import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
    Button,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import AppNavbar from '../AppNavbar';
import './UserProfileView.css';
import UserService from "../job/service/UserService";

const JobApplicantProfileView = () => {
    const initialFormState = {};
    const [profile, setProfile] = useState(initialFormState);
    const {id} = useParams();
    const [alertData, setAlertData] = useState({color: 'danger', message: '', visible: false});
    const [deleteModalDialog, setDeleteModalDialog] = useState(false);
    const userService = UserService();

    const toggle = () => setDeleteModalDialog(!deleteModalDialog);

    useEffect(() => {

        userService.getApplicantProfile(id)
            .then(response => {
                const data = response.data;
                setProfile(data)
            });
    }, [id, setProfile]);

    const onDismiss = () => setAlertData({...alertData, visible: false});

    const rejectProfile = async (event) => {
        event.preventDefault();
        await updateStatus(id, 'Rejected');
    };

    const shortlistProfile = async (event) => {
        event.preventDefault();
        await updateStatus(id, 'Shortlisted');
    };

    const updateStatus = async (id, status) => {
        const payload = {'id': id, 'status': status, jobId: profile.jobId};
        const response = await userService.updateJobApplication(payload);

        if (response.status === 200) {
            setAlertData({color: 'info', message: "Profile updated.", visible: true});
        } else {
            setAlertData({color: 'danger', message: response.data, visible: true});
        }
    };

    return (
        <div>
            <AppNavbar/>
            <Container>
                <h3>Applicant Profile</h3>
                <div className="profile-header">
                    <img src={profile.profilePicture || '/logo512.png'} alt="Profile" className="profile-picture"/>
                    <div>
                        <h2 className="profile-name">{profile.firstName || ''} {profile.lastName || ''}</h2>
                        <h4 className="profile-email">{profile.email || ''}</h4>
                    </div>
                </div>
                <Form className="col-md-6">

                    <FormGroup>
                        <Label for="company">Company</Label>
                        <Input type="text" name="company" id="company"
                               value={profile.company || ''}
                               disabled
                               placeholder="Microsoft, Meta, AMazon, etc.."/>
                    </FormGroup>

                    <FormGroup>
                        <Label for="skills">Skills</Label>
                        <Input type="text" name="skills" id="skills"
                               value={profile.skills || ''}
                               disabled
                               placeholder="Python, Java, Kotlin etc.."/>
                    </FormGroup>

                    <FormGroup>
                        <Label for="experienceLevel">Experience Level</Label>
                        <Input type="text" name="experienceLevel" id="experienceLevel"
                               disabled
                               value={profile.experienceLevel || ''}>
                        </Input>
                    </FormGroup>

                    <FormGroup>
                        <Label for="preferredLocation">Preferred Location</Label>
                        <Input type="text" name="preferredLocation" id="preferredLocation"
                               value={profile.preferredLocation || ''}
                               disabled
                               placeholder="New York, London, Berlin, Remote Etc.."/>
                    </FormGroup>

                    <div className="d-flex justify-content-center">
                        <FormGroup className="col-md-2">
                            <Button color="success" type="button" outline
                                    onClick={shortlistProfile}>Shortlist</Button>{' '}
                        </FormGroup>
                        <FormGroup className="col-md-3">
                            <Button color="danger" type="button" outline onClick={toggle}>Reject Profile</Button>{' '}
                        </FormGroup>
                    </div>
                    <FormGroup>
                        <Alert color="info" isOpen={alertData.visible} toggle={onDismiss}>
                            {alertData.message}
                        </Alert>
                    </FormGroup>

                    <Modal isOpen={deleteModalDialog} toggle={toggle}>
                        <ModalHeader toggle={toggle}>Reject Profile</ModalHeader>
                        <ModalBody>
                            Are you sure you want to reject the profile?
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={rejectProfile}>
                                Yes
                            </Button>{' '}
                            <Button color="secondary" onClick={toggle}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>

                </Form>
            </Container>
        </div>
    );
};

export default JobApplicantProfileView;