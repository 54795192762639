import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
    Alert,
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';
import AppNavbar from '../AppNavbar';
import './UserProfileView.css';
import UserService from "../job/service/UserService";

const UserProfileDetailsView = () => {
    const initialFormState = {
        userType: '',
        name: '',
        firstName: '',
        lastName: '',
        email: '',
        linkedInProfile: '',
        gitHubProfile: '',
        professionalSummary: '',
        skills: '',
        experienceLevel: '',
        preferredLocation: '',
        preferredJobType: '',
        preferredJobRole: '',
        preferredSalary: '',
        profilePicture: '',
        calendarUrl: '',
        locale: '',
    };
    const [profile, setProfile] = useState(initialFormState);
    const {id} = useParams();
    const [alertData, setAlertData] = useState({color: 'danger', message: '', visible: false});
    const [deleteModalDialog, setDeleteModalDialog] = useState(false);
    const userService = UserService();

    const toggle = () => setDeleteModalDialog(!deleteModalDialog);

    useEffect(() => {
        userService.getProfile()
            .then(response => {
                const data = response.data;
                setProfile(data)
            });
    }, [id, setProfile]);

    const onDismiss = () => setAlertData({...alertData, visible: false});

    const handleChange = (event) => {
        const {name, value} = event.target;
        setProfile({...profile, [name]: value});
    };

    const logout = () => {
        userService.logout()
            .then(response => {
                const data = response.data;
                window.location.href = `${data.logoutUrl}?id_token_hint=${data.idToken}`
                    + `&post_logout_redirect_uri=${window.location.origin}`;
            });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const response = await userService.updateProfile(profile);

        if (response.status === 200) {
            setAlertData({color: 'info', message: "Profile updated.", visible: true});
        } else {
            setAlertData({color: 'danger', message: response.data, visible: true});
        }
    };

    const deleteProfile = async (event) => {
        event.preventDefault();
        toggle();
        const response = await userService.deleteProfile();
        if (response.status === 200) {
            logout();
        } else {
            setAlertData({color: 'danger', message: response.data, visible: true});
        }
    };

    return (
        <div>
            <AppNavbar/>
            <Container>
                <div className="profile-header">
                    <img src={profile.profilePicture || '/logo512.png'} alt="Profile" className="profile-picture"/>
                    <div>
                        <h2 className="profile-name">{profile.firstName || ''} {profile.lastName || ''}</h2>
                        <h4 className="profile-email">{profile.email || ''}</h4>
                    </div>
                </div>
                <Form onSubmit={handleSubmit} className="col-md-12">
                    <Row>
                        <Col md="6">
                            {profile.userType === "Job Seeker" && (
                                <>

                                    <FormGroup>
                                        <Label for="compnay">Company</Label>
                                        <Input type="text" name="company" id="company"
                                               value={profile.company || ''}
                                               onChange={handleChange}
                                               placeholder="Enter your current organization"/>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="skills">Skills</Label>
                                        <Input type="text" name="skills" id="skills"
                                               value={profile.skills || ''}
                                               onChange={handleChange}
                                               placeholder="Enter your current skills (ex. Python, Kotlin etc..)"/>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="linkedInProfile">LinkedIn Profile</Label>
                                        <Input type="url" name="linkedInProfile" id="linkedInProfile"
                                               value={profile.linkedInProfile || ''}
                                               onChange={handleChange}
                                               placeholder="https://www.linkedin.com/in/<your-handle>"/>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="gitHubProfile">GitHub Profile</Label>
                                        <Input type="url" name="gitHubProfile" id="gitHubProfile"
                                               value={profile.gitHubProfile || ''}
                                               onChange={handleChange}
                                               placeholder="https://github.com/<your-handle>"/>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="professionalSummary">Professional Summary</Label>
                                        <Input type="textarea" name="professionalSummary" id="professionalSummary"
                                               value={profile.professionalSummary || ''}
                                               onChange={handleChange}
                                               placeholder="Summary of your professional experience"/>
                                    </FormGroup>

                                </>
                            )}

                            {profile.userType === "Recruiter/Hiring Manager" && (
                                <>
                                    <FormGroup>
                                        <Label for="company">Company</Label>
                                        <Input type="text" name="company" id="company"
                                               value={profile.company || ''}
                                               onChange={handleChange}
                                               placeholder="Microsoft, Meta, Amazon, etc.."/>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="skills">Skills</Label>
                                        <Input type="text" name="skills" id="skills"
                                               value={profile.skills || ''}
                                               onChange={handleChange}
                                               placeholder="Python, Java, Kotlin etc.."/>
                                    </FormGroup>
                                </>
                            )}

                            {profile.userType === "Career Coach" && (
                                <>
                                    <FormGroup>
                                        <Label for="professionalSummary">Professional Summary</Label>
                                        <Input type="textarea" name="professionalSummary" id="professionalSummary"
                                               value={profile.professionalSummary || ''}
                                               onChange={handleChange}
                                               placeholder="Summary of your professional experience"/>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="calendarUrl">Calendar Url</Label>
                                        <Input type="url" name="calendarUrl" id="calendarUrl"
                                               value={profile.calendarUrl || ''}
                                               onChange={handleChange}
                                               placeholder="Calendar URL"/>
                                    </FormGroup>
                                </>
                            )}
                        </Col>
                        <Col md="6">
                            {profile.userType === "Job Seeker" && (
                                <>
                                    <FormGroup>
                                        <Label for="experienceLevel">Experience Level</Label>
                                        <Input type="select" name="experienceLevel" id="experienceLevel"
                                               value={profile.experienceLevel || ''}
                                               onChange={handleChange}>
                                            <option value="">Select Experience Level</option>
                                            <option value="Internship">Internship</option>
                                            <option value="Entry level">Entry level</option>
                                            <option value="Associate">Associate</option>
                                            <option value="Mid-Senior level">Mid-Senior level</option>
                                            <option value="Director">Director</option>
                                            <option value="Executive">Executive</option>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="preferredLocation">Location</Label>
                                        <Input type="text" name="preferredLocation" id="preferredLocation"
                                               value={profile.preferredLocation || ''}
                                               onChange={handleChange}
                                               placeholder="New York, London, Berlin, Remote Etc.."/>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="preferredJobType">Job Type</Label>
                                        <Input type="select" name="preferredJobType" id="preferredJobType"
                                               value={profile.preferredJobType || ''} onChange={handleChange}>
                                            <option value="">Select Job Type</option>
                                            <option value="Full-time">Full-time</option>
                                            <option value="Part-time">Part-time</option>
                                            <option value="Contract">Contract</option>
                                            <option value="Internship">Internship</option>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="preferredJobRole">Job Title</Label>
                                        <Input type="text" name="preferredJobRole" id="preferredJobRole"
                                               value={profile.preferredJobRole || ''}
                                               onChange={handleChange}
                                               placeholder="Ex. Senior Backend Engineer, Full Stack Developer etc.."/>
                                    </FormGroup>
                                </>
                            )}

                            {profile.userType === "Recruiter/Hiring Manager" && (
                                <>
                                    <FormGroup>
                                        <Label for="experienceLevel">Experience Level</Label>
                                        <Input type="select" name="experienceLevel" id="experienceLevel"
                                               value={profile.experienceLevel || ''}
                                               onChange={handleChange}>
                                            <option value="">Select Experience Level</option>
                                            <option value="Internship">Internship</option>
                                            <option value="Entry level">Entry level</option>
                                            <option value="Associate">Associate</option>
                                            <option value="Mid-Senior level">Mid-Senior level</option>
                                            <option value="Director">Director</option>
                                            <option value="Executive">Executive</option>
                                        </Input>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="preferredLocation">Preferred Location</Label>
                                        <Input type="text" name="preferredLocation" id="preferredLocation"
                                               value={profile.preferredLocation || ''}
                                               onChange={handleChange}
                                               placeholder="New York, London, Berlin, Remote Etc.."/>
                                    </FormGroup>
                                </>
                            )}
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-center">
                        <FormGroup className="col-md-2">
                            <Button outline color="primary" type="submit">Save</Button>{' '}
                        </FormGroup>
                        <FormGroup className="col-md-2">
                            <Button outline color="danger" type="button" onClick={toggle}>Delete Profile</Button>{' '}
                        </FormGroup>
                    </div>
                    <FormGroup>
                        <Alert color="info" isOpen={alertData.visible} toggle={onDismiss}>
                            {alertData.message}
                        </Alert>
                    </FormGroup>

                    <Modal isOpen={deleteModalDialog} toggle={toggle}>
                        <ModalHeader toggle={toggle}>Profile Deletion</ModalHeader>
                        <ModalBody>
                            Are you sure you want to delete your profile?
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={deleteProfile}>
                                Yes
                            </Button>{' '}
                            <Button color="secondary" onClick={toggle}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>

                </Form>
            </Container>
        </div>
    );
};

export default UserProfileDetailsView;