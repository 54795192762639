import React, {useEffect, useState} from 'react';
import {
    Badge,
    Button,
    ButtonGroup,
    Container,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table
} from 'reactstrap';
import {Link} from 'react-router-dom';
import LoadingView from "../../../common/LoadingView";
import JobService from "../../service/JobService";

const EmployeeJobListView = (userType) => {

    const [jobApplications, setJobApplications] = useState([]);
    const [loading, setLoading] = useState(false);
    const jobService = JobService();
    const [deleteModalDialog, setDeleteModalDialog] = useState(false);
    const [selectedJobApplication, setSelectedJobApplication] = useState(null);

    const toggle = (jobApplication) => {
        setSelectedJobApplication(jobApplication);
        setDeleteModalDialog(!deleteModalDialog);
    }
    const [alertData, setAlertData] = useState({color: 'danger', message: '', visible: false});

    useEffect(() => {
        setLoading(true);

        jobService.getEmployeeAppliedJobs()
            .then(response => {
                const data = response.data;
                setJobApplications(data);
                setLoading(false);
            })
    }, []);

    if (loading) {
        return <LoadingView/>
    }

    const withdrawJobApplication = async (event) => {
        event.preventDefault();
        setDeleteModalDialog(!deleteModalDialog);
        selectedJobApplication.status = 'Withdrawn';
        await updateJobApplication(selectedJobApplication);
    };

    const updateJobApplication = async (jobApplication) => {
        const response = await jobService.updateJobApplication(jobApplication);

        if (response.status === 200) {
            setAlertData({color: 'info', message: "Job application withdrawn.", visible: true});
        } else {
            setAlertData({color: 'danger', message: response.data, visible: true});
        }
    };

    const jobApplicationsList = jobApplications.map(jobApplication => {
        const formattedDate = new Date(jobApplication.createdDate).toLocaleDateString('en-CA');
        return <tr key={jobApplication.jobId}>
            <td>{formattedDate}</td>
            <td>
                <Link className="link-underline-light" to={`/jobs/view/${jobApplication.jobId}`}>
                    {jobApplication.title}
                </Link>
            </td>
            <td>{jobApplication.company}</td>
            <td>{jobApplication.location}</td>
            <td> {jobApplication.status === "Applied" && (
                <Badge pill color="info">Applied</Badge>
            )}
                {jobApplication.status === "Shortlisted" && (
                    <Badge pill color="success">{jobApplication.status}</Badge>
                )}
                {jobApplication.status === "Rejected" && (
                    <Badge pill color="danger"
                    >{jobApplication.status}</Badge>
                )}
                {jobApplication.status === "Withdrawn" && (
                    <Badge pill color="warning"
                    >{jobApplication.status}</Badge>
                )}</td>
            <td>
                {jobApplication.status !== "Withdrawn" && (
                    <ButtonGroup>
                        <Button size="sm" color="danger" outline
                                onClick={(event) => toggle(jobApplication)}>Withdraw</Button>
                    </ButtonGroup>
                )}

                {jobApplication.status === "Withdrawn" && (
                    <Label> - </Label>
                )}
            </td>
        </tr>
    });

    return (
        <div>
            <Container fluid>
                <h5 className="mt-1">Applied Jobs</h5>
                <Table className="mt-4">
                    <thead>
                    <tr>
                        <th width="20%">Applied On</th>
                        <th width="20%">Job Title</th>
                        <th width="20%">Company</th>
                        <th width="10%">Location</th>
                        <th width="10%">Status</th>
                        <th width="10%">Action</th>

                    </tr>
                    </thead>
                    <tbody>
                    {jobApplicationsList}
                    </tbody>
                </Table>
                <Modal isOpen={deleteModalDialog} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Withdraw Job Application</ModalHeader>
                    <ModalBody>
                        Are you sure you want to withdraw the job application?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={withdrawJobApplication}>
                            Yes
                        </Button>{' '}
                        <Button color="secondary" onClick={toggle}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </Container>
        </div>
    );
};

export default EmployeeJobListView;
