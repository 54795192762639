import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup, Container, Form, FormGroup, Input, Table} from 'reactstrap';
import {Link, useLocation} from 'react-router-dom';
import LoadingView from "../../../common/LoadingView";
import JobService from "../../service/JobService";
import AppNavbar from "../../../AppNavbar";
import JobCard from "../../../common/JobCard";

const JobSearchResultsView = (userType) => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(false);
    const location = useLocation();

    const jobService = JobService();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const skills = params.get('skills') || '';
        const jobLocation = params.get('location') || '';

        setLoading(true);
        jobService.searchJobs({skills, location: jobLocation}).then(response => {
            setJobs(response.data);
            setLoading(false);
        });
    }, [location.search]);

    if (loading) {
        return <LoadingView/>
    }

    const jobsList = jobs.map(job => {
        return <JobCard key={job.id} job={job} userType={userType} showApplyButton={true}/>
    });

    return (
        <div>
            <AppNavbar/>
            <Container fluid>
                <h3>Search Results</h3>
                {jobsList}
            </Container>
        </div>
    );
};

export default JobSearchResultsView;