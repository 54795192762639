import React, {useEffect, useState} from 'react';
import {
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    UncontrolledDropdown
} from 'reactstrap';
import {Link, useNavigate} from 'react-router-dom';
import './AppNavbar.css';
import LoadingView from "./common/LoadingView";
import UserService from "./job/service/UserService";

const AppNavbar = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(undefined);
    const navigate = useNavigate();
    const userService = UserService();

    useEffect(() => {
        setLoading(true);
        userService.getUser().then(response => {
            const data = response.data
            if (data === '') {
                setAuthenticated(false);
                navigate('/home');
            } else {
                setUser(data);
                setAuthenticated(true);
            }
            setLoading(false);
        });
    }, [setAuthenticated, setLoading, setUser])

    const logout = () => {
        userService.logout().then(response => {
            const data = response.data;
            window.location.href = `${window.location.origin}?id_token_hint=${data.idToken}`
                + `&post_logout_redirect_uri=${window.location.origin}`;
        }).catch(err => {
            console.error(err);
        });
    }

    const profile = () => {
        navigate('/profile');
    }
    const button = authenticated ?
        <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav>
                <div className="d-flex align-items-center ml-auto">
                    <img
                        src={user.picture || '/logo512.png'}
                        className="rounded-circle mr-3"
                        width="40"
                        height="40"
                        alt=""/>
                    <div className="m-lg-3">{user.name}</div>
                </div>
            </DropdownToggle>
            <DropdownMenu end>
                <DropdownItem onClick={profile}>Profile</DropdownItem>
                <DropdownItem divider/>
                <DropdownItem onClick={logout}>Logout</DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
        :
        <></>;

    if (loading) {
        return <LoadingView/>
    }

    return (
        <Navbar color="dark" dark expand="md">
            <NavbarBrand tag={Link} to="/home" className="navbar-brand-custom">
                RiffedTalent
                <small className="navbar-tagline">Accelerating Placement, Empowering Talent</small>
            </NavbarBrand>
            <NavbarToggler onClick={() => {
                setIsOpen(!isOpen)
            }}/>
            <Collapse isOpen={isOpen} navbar>
                <Nav className="justify-content-end" style={{width: "100%"}} navbar>
                    {button}
                </Nav>
            </Collapse>
        </Navbar>
    );
};

export default AppNavbar;