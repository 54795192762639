import JobListView from "./JobListView";
import React from "react";

const EmployerDashboard = (user) => {

    return (
        <div>
            <JobListView userType={user.userType}/>
        </div>
    );
}

export default EmployerDashboard;
