import React from 'react';

const BrandLogo = () => {
    return (
        <div className="flex-shrink-0">
            <span className="text-2xl text-[#007B5]">
                <a href="/home" className="text-2xl font-bold text-[#0077B5] no-underline">RiffedTalent</a>
            </span>
        </div>
    );
};

export default BrandLogo;