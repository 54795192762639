import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
    Button,
    Container,
    Form,
    FormGroup,
    Input,
    Alert, Label,
} from 'reactstrap';
import '../employeer/NewJobView.css';
import AppNavbar from "../../../AppNavbar";
import '@mdxeditor/editor/style.css'
import ReactMarkdown from 'react-markdown';
import Job from "../../model/Job";
import JobService from "../../service/JobService";

const JobDisplayView = () => {

    const initialFormState = new Job('', '', '', '', '', '', '', '', '');
    const [job, setJob] = useState(initialFormState);
    const {id} = useParams();
    const [alertData, setAlertData] = useState({color: 'danger', message: '', visible: false});
    const jobService = JobService();

    useEffect(() => {
        jobService.getJob(id).then(response => {
            setJob(response.data);
        });
    }, [id, setJob]);

    const onDismiss = () => setAlertData({...alertData, visible: false});

    const handleSubmit = async (event) => {
        event.preventDefault();
        const response = await jobService.applyJob(id);

        if (response.status === 200) {
            setAlertData({color: 'info', message: "Job application successful.", visible: true});
        } else {
            setAlertData({color: 'danger', message: response.data, visible: true});
        }

    };

    return (
        <div>
            <AppNavbar/>
            <Container>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <div className="row">
                            <div className="col-md-4">
                                <FormGroup className="col-md-6">
                                    <h4>Job Details</h4>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="company">Company</Label>
                                    <Input type="text" name="company" id="company"
                                           value={job.company || ''}
                                           disabled/>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="title">Job Title</Label>
                                    <Input type="text" name="title" id="title"
                                           value={job.title || ''}
                                           disabled/>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="location">Job Location</Label>
                                    <Input type="text" name="location" id="location"
                                           value={job.location || ''}
                                           disabled/>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="skills">Skills</Label>
                                    <Input type="text" name="skills" id="skills"
                                           value={job.skills || ''}
                                           disabled/>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="experienceLevel">Experience Level</Label>
                                    <Input type="text" name="experienceLevel" id="experienceLevel"
                                           value={job.experienceLevel || ''} disabled>
                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="jobType">Type</Label>
                                    <Input type="text" name="jobType" id="jobType" disabled
                                           value={job.jobType || ''}>
                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="jobRole">Role</Label>
                                    <Input type="text" name="jobRole" id="jobRole"
                                           value={job.jobRole || ''}
                                           disabled/>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="salary">Salary</Label>
                                    <Input type="text" name="salary" id="salary"
                                           value={job.salary || ''}
                                           disabled/>
                                </FormGroup>

                            </div>
                            <div className="col-md-6">
                                <Label for="jobDescription">Job Description</Label>
                                <FormGroup style={{maxHeight: '650px', overflowY: 'auto'}}>
                                    <ReactMarkdown className="editor-content">
                                        {job.jobDescription || ''}
                                    </ReactMarkdown>
                                </FormGroup>
                            </div>

                        </div>
                        <div className="row">
                            <FormGroup className="offset-4 col-md-8">
                                <Button outline color="primary" type="submit">Apply</Button>{' '}
                            </FormGroup>
                        </div>
                    </FormGroup>

                    <FormGroup className="offset-2 col-md-4">
                        <Alert color={alertData.color} isOpen={alertData.visible} toggle={onDismiss}>
                            {alertData.message}
                        </Alert>
                    </FormGroup>

                </Form>
            </Container>
        </div>
    );
};

export default JobDisplayView;