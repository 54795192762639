import React, {useEffect, useState} from 'react';
import {Button, Container, Form, FormGroup, Input} from 'reactstrap';
import LoadingView from "../../../common/LoadingView";
import JobSearch from "../../model/JobSearch";
import JobService from "../../service/JobService";
import {useNavigate} from "react-router-dom";

const JobSearchView = (userType) => {

    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const initialFormState = new JobSearch('', '');
    const [search, setSearch] = useState(initialFormState);
    const jobService = JobService();

    useEffect(() => {
        setLoading(true);
        jobService.getEmployeeJobRecommendations().then(response => {
            setJobs(response.data);
            setLoading(false);
        });
    }, [setJobs]);

    if (loading) {
        return <LoadingView/>
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
        setSearch({...search, [name]: value});
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (search.skills.trim() === '' && search.location.trim() === '') {
            return;
        }
        // navigate to search results with search criteria
        navigate(`/jobs/search?skills=${search.skills}&location=${search.location}`);
        try {
            await jobService.searchJobs(search);
        } catch (error) {
            // setAlertData({color: 'danger', message: "Failed with error: " + error.error, visible: true});
        }
    };
    return (
        <div>
            <Container fluid>
                <Form onSubmit={handleSubmit}>
                    <div className="row">
                        <FormGroup className="offset-md-3 col-md-3">
                            <Input type="text" name="skills" id="skills" placeholder="Title, Company, Skills, Location"
                                   onChange={handleChange}/>
                        </FormGroup>
                        <FormGroup className="col-md-2">
                            <Button outline type="submit" color="primary">Search</Button>
                        </FormGroup>
                    </div>
                </Form>
            </Container>
        </div>
    );
};

export default JobSearchView;
